.table
    border-collapse: separate !important
    border-spacing: 0 !important
    width: 100%
    margin-top: 2.5rem

.table tr:nth-child(odd)
    td
        border-top: 1px solid #e1e1e1
        border-bottom: 1px solid #e1e1e1

.table, tr, td 
            mat-form-field
                div
                    margin-top: 10px